<template>
  <router-link :to="{ name: 'projectDetails', params: { slug: item.slug } }">
    <div class="projects__tile" :class="{ 'projects__tile--lg': isLg }">
      <h5 v-text="item.name" class="projects__tile__title truncate" />
      <b-img
        fluid
        :src="item.main_media && item.main_media.path"
        class="projects__tile__img"
      />
      <!-- <p v-text="description" class="projects__tile__description truncate" /> -->
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ProjectTile",

  props: {
    item: {
      type: Object,
      required: true
    },
    isLg: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    description() {
      if (this.item.short_description) {
        return this.item.short_description;
      } else if (this.item.description) {
        return this.item.description;
      } else {
        return "";
      }
    }
  }
};
</script>
